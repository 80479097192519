import React,{ useEffect, useState } from 'react'
// import readmePath from "../components/background.md";
// import { marked } from "marked";
// import ReactMarkdown from 'react-markdown';
import MarkdownIt from 'markdown-it';
import MdEditor from 'react-markdown-editor-lite';
import 'react-markdown-editor-lite/lib/index.css';
import {Dimmer, Loader, Image, Segment  } from 'semantic-ui-react';

const baseUrl = process.env.REACT_APP_BASE_URL

function ReadMarkdown2() {
    const [data, setData] = useState("");

    const mdParser = new MarkdownIt();
    const [isLoading, setIsLoading]= useState(false)
    
    console.log(process.env.REACT_APP_BASE_URL);
    useEffect(() => {
      // Define an async function to fetch and process data
      const fetchData = async () => {
        setIsLoading(true)
        try {
          // Replace with your actual API call
          const response = await fetch(`${baseUrl}/bot/markdown_data/`);
          let data = await response.json();  // Assume response is a promise
          // console.log(data.data); // Store the resolved data as an array in state
          data = data.data
          // console.log(data.join(""))
          data=data.join("")
          // console.log(data)
          setData(data);
          setIsLoading(false)
        } catch (error) {
          console.error("Error fetching data:", error);
          setIsLoading(false)
        }
      };
  
      fetchData(); // Call the async function
    }, []);

    function handleEditorChange({ html, text }) {
      console.log('handleEditorChange', html, text);
    }
    return (
      <div>
      {isLoading?(<div>
        <Segment>
      <Dimmer active inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>

      <Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
    </Segment>
      </div>):(<div className="markdown-container">
      <MdEditor
      value={data}
        renderHTML={(text) => mdParser.render(text)}
        onChange={handleEditorChange}
        view={{ menu: false, md: false, html: true }}
          // Disable editor and menu, enable preview
      />
      </div>)}
      </div>
    );
}

export default ReadMarkdown2