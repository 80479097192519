import React, { useState, useEffect } from 'react';
import MarkdownIt from 'markdown-it';
import MdEditor from 'react-markdown-editor-lite';
import 'react-markdown-editor-lite/lib/index.css';
import { useNavigate } from "react-router-dom";
// import markdownfile from '../components/test.md';
// import markdownfile from "../components/background.md";
import axios from 'axios';
import { Button, Icon, Header, HeaderSubheader,Dimmer, Loader, Image, Segment  } from 'semantic-ui-react';
import SaveModal from '../modal/SaveModal';

// import { marked } from "marked";
const baseUrl= process.env.REACT_APP_BASE_URL

const MarkdownEditorLite = () => {
  const [markdown, setMarkdown] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [completedEdit,setCompletedEdit]=useState(false)
  const [fileName, setFileName] = useState(""); // Holds the file name
  const [useFileUpload,setUseFileUpload] = useState(false)
  const [loading,setLoading] = useState(false)
  const mdParser = new MarkdownIt();
  const navigate = useNavigate()
  

  // Function to fetch the markdown file
  useEffect(() => {
    // Define an async function to fetch and process data
    const fetchData = async () => {
      try {
        // Replace with your actual API call
        const response = await fetch(`${baseUrl}/bot/markdown_data/`);
        let data = await response.json();  // Assume response is a promise
        data = data.data
        // console.log(data.join(""))
        data=data.join("")
        // console.log(data)
        setMarkdown(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    let savedMarkdown = localStorage.getItem("markdown");
    if (savedMarkdown) {
      // console.log(savedMarkdown)
      setMarkdown(savedMarkdown);
    }else{
      fetchData(); 
    }
    // Call the async function
  }, []);

  // useEffect(() => {
  //   let info = localStorage.getItem("markdown");
  //   if(info){
  //     setMarkdown(info)
  //   }
  // }, []);

  // useEffect(() => {
    
  // }, [markdown]);

  const updateMarkdown = async (commit_message,dataFunc,errorFunc) => {
    // console.log(commit_message)
    setLoading(true)
    try {
      const response = await axios.post(`${baseUrl}/bot/markdown/update/`, {
        content: markdown,
        commit_message: commit_message,
      });
      
      if (response.data.status === 'success') {
        // alert('Markdown updated successfully');
        response.data.status = null
        
        dataFunc(response)
        localStorage.removeItem("markdown");
        
        
        setUseFileUpload(false)
        setLoading(false)
        setCompletedEdit(true)
      } else {
        // alert('Error updating markdown');
        errorFunc(response)
      }
    } catch (error) {
      console.error('Error updating markdown:', error);
    }
  };

  // Toggle between view and edit mode
  const toggleEditMode = () => {
    setIsEditing(!isEditing);
    // updateMarkdown()
  };

  const secondOption = () => {
    // setIsEditing(!isEditing);
    updateMarkdown()
  };
  // Handle markdown change
  const handleEditorChange = ({ text }) => {
    setMarkdown(text);
    localStorage.setItem("markdown", text);
  };

  if(completedEdit){
    setTimeout(() => {
          
    }, 10000);
    
    navigate("/bot/home",{ replace: true })
    setCompletedEdit(false)
  }
  

  const downloadMarkdown = () => {
    const blob = new Blob([markdown], { type: 'text/markdown' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'dataset.md';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };


  // Function to handle file upload
  const handleFileUpload = (event) => {
    const file = event.target.files[0]; // Get the selected file
    if (file) {
      const reader = new FileReader();
      reader.onload = function(e) {
        const fileContent = e.target.result;
        setMarkdown(fileContent); // Set the file content as markdown
        setUseFileUpload(true)
        setFileName(file.name); // Set the file name for reference
      };
      reader.readAsText(file); // Read the file as text
    }
  };

console.log(fileName)
  return (
    <div>
      
      {loading?(
      <Segment>
      <Dimmer active inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>

      <Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
    </Segment>):(
      <div>
        <div className='button-array'>
        <Button  primary as="a" href="https://www.markdownguide.org/basic-syntax/" target="_blank">
          markdown<br/>
          guidlines
        </Button>
        {isEditing?(
          <SaveModal sendFunction={updateMarkdown}/>
          ):(
          <Button color='green' onClick={isEditing?secondOption:toggleEditMode}>
            {isEditing ? 'Save Changes' : useFileUpload?'check/edit uploaded file':'Edit Dataset'}
          </Button>
        )}
        <Button color='teal' onClick={downloadMarkdown}>
          <Icon name="download"/>Download
        </Button>
        <Button color='teal'>
          <Icon name='file'/>Upload
          <input type="file" accept=".md" onChange={handleFileUpload}></input>
        </Button>
      </div>
      <br/>
      <div>
        <Header as="h6">
          <HeaderSubheader>
            Select Edit button to edit on the page, save to commit change <br/>
            Select Upload button to upload markdown file, check to confirm, save to commit <br/>
            Select Download button to download dataset 
          </HeaderSubheader>
          </Header>
      </div>
      <div>
      {isEditing ? (
        <MdEditor
          value={markdown}
          renderHTML={(text) => mdParser.render(text)}
          onChange={handleEditorChange}
        />
      ) : (
        <div className="markdown-container">
         <MdEditor
          value={markdown}
          renderHTML={(text) => mdParser.render(text)}
          onChange={handleEditorChange}
          view={{ menu: false, md: false, html: true }}
        />
        </div>
      )}
      
      </div>
      </div>
    )}
    </div>
  );
};

export default MarkdownEditorLite;